<template>
  <div class="w-auto">
    <button
      @click="$emit('click')"
      class="border border-secundB bg-secund hover:bg-gray-300 rounded-lg text-black font-bold py-1 px-2 sm:py-2 sm:px-4 inline-flex items-center focus:outline-none"
    >
      <slot></slot><span class="text-botonT">{{ botonsText }}</span>
    </button>
  </div>
</template>

<script>
export default {
  name: "botonSecundario",
  props: ["botonsText"]
};
</script>
