<template>
    <div>
        <svg
                class="fill-current text-black h-8 w-16"
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 220 512 40"
                style="enable-background:new 0 0 512 512;"
                xml:space="preserve">
                    <g id="_13-car">
                        <g id="linear_color">
                            <circle cx="242.8" cy="291.1" r="8.2" />
                            <circle cx="438.4" cy="291.1" r="8.2" />
                            <path d="M485.5,226.6l-60-9.1l-57.5-47.3c-5.4-4.4-12.1-6.8-19-6.8h-79.6c-9.1,0-17.7,4.1-23.3,11.2l-34.5,43.1h-23.2 c-13.5,0-24.4,11-24.5,24.5v27.2c0,16.5,13.4,29.9,29.9,29.9h14.5c4.5,19,23.5,30.7,42.5,26.2c13-3.1,23.1-13.2,26.2-26.2H404 c4.5,19,23.5,30.7,42.5,26.2c13-3.1,23.1-13.2,26.2-26.2h9.1c13.5,0,24.4-11,24.5-24.5v-24C506.4,238.7,497.5,228.4,485.5,226.6z M357.7,182.8l42.5,34.9h-78.6v-38h27.5C352.2,179.7,355.3,180.8,357.7,182.8z M258.9,184.8c2.6-3.2,6.5-5.1,10.6-5.1h3.1v38 h-40.1L258.9,184.8z M242.8,310.1c-10.5,0-19-8.5-19-19s8.5-19,19-19c10.5,0,19,8.5,19,19C261.8,301.6,253.3,310.1,242.8,310.1z M438.4,310.1c-10.5,0-19-8.5-19-19s8.5-19,19-19s19,8.5,19,19C457.4,301.6,448.9,310.1,438.4,310.1z M490,274.8 c0,4.5-3.7,8.1-8.2,8.2h-9.1c-4.5-19-23.5-30.7-42.5-26.2c-13,3.1-23.1,13.2-26.2,26.2H277.1c-4.5-19-23.5-30.7-42.5-26.2 c-13,3.1-23.1,13.2-26.2,26.2h-14.5c-7.5,0-13.6-6.1-13.6-13.6v-27.2c0-4.5,3.7-8.1,8.2-8.2h92.4c4.5,0,8.2-3.6,8.2-8.2v-46.2 h16.3v46.2c0,4.5,3.6,8.2,8.2,8.2h112.5l57.1,8.7c4,0.6,6.9,4,6.9,8.1V274.8z"/>
                            <path d="M324.3,239.5h-10.9c-4.5,0-8.2,3.6-8.2,8.2s3.6,8.2,8.2,8.2h10.9c4.5,0,8.2-3.6,8.2-8.2S328.8,239.5,324.3,239.5z"/>
                            <circle cx="242.8" cy="291.1" r="8.2" />
                            <circle cx="438.4" cy="291.1" r="8.2" />
                        </g>
                    </g>
                    <rect x="63.2" y="193.1" width="120.9" height="16.3" />
                    <rect x="4" y="234.1" width="120.9" height="16.3" />
                    <rect x="29.7" y="275.4" width="120.9" height="16.3" />
              </svg>
    </div>
</template>
<script>
export default {
    name:'vendedor',
}
</script>